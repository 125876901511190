//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import Spinner from "@/components/Loading/Spinner";
import { getMarketplacesList } from "@/utils/enums";
export default {
  components: {
    DataTable,
    Spinner
  },
  props: {},
  data() {
    return {
      prev: null
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    async tableLoader({
      query
    }) {
      let result = await this.$store.dispatch("reviewManagement/queryTokens", query);
      result = {
        items: [...result.data],
        total: result.data.length,
        success: result.success,
        message: result.message
      };
      return result;
    }
  },
  mounted() {
    let interval = setInterval(async () => {
      if (!this.$refs.table) {
        clearInterval(interval);
        return;
      }
      this.refresh();
    }, 10000);
  },
  computed: {
    columns() {
      return [{
        title: "商店名称",
        position: 1,
        show: true,
        name: "name",
        width: 120,
        sort: false
      }, {
        title: "市场",
        position: 2,
        show: true,
        name: "datasource",
        getText: v => getMarketplacesList()[v],
        width: 120
      }, {
        title: "令牌(token)",
        position: 3,
        show: true,
        name: "apikey",
        width: 450,
        noOverflow: true
      },
      // {
      //     title: "Client Id",
      //     position: 4,
      //     show: true,
      //     name: "client_id",
      //     width: 100,
      //     noOverflow: true,
      // },
      {
        title: "商店状态",
        position: 5,
        show: true,
        name: "status",
        width: 200,
        type: "slot",
        getText: item => item
      }, {
        title: "最后更新",
        position: 6,
        show: true,
        name: "last_update",
        type: "text",
        width: 120
      }, {
        title: "",
        position: 7,
        show: true,
        name: "_actions",
        type: "actions",
        actions: [{
          title: "删除",
          action: (item, {
            refresh
          }) => {
            this.$emit("remove", {
              item,
              context: {
                refresh
              }
            });
          }
        }, {
          title: "编辑",
          action: (item, {
            refresh
          }) => {
            this.$emit("edit", {
              item,
              context: {
                refresh
              }
            });
          }
        }],
        width: 40
      }];
    }
  }
};