//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalAddConnection from "@/components/modals/ModalAddReviewManagementConnection.vue";
import ModalUpdateConnection from "@/components/modals/ModalUpdateReviewManagementConnection.vue";
import ModalRemoveConnection from "@/components/modals/ModalRemoveReviewManagementConnection.vue";
import MainTitle from "@/components/MainTitle.vue";
import ConnectionsTable from "@/components/ReviewManagement/ConnectionsTable";
// import Warning from "@/components/Alert/Warning";
import Badge from "@/components/Badge.vue";
import WarningAllLK from "@/components/Alert/WarningAllLK";
export default {
  meta() {
    return {
      title: "对客户评论的分析和自动响应",
      meta: [{
        name: "description",
        content: "对客户评论的分析和自动响应"
      }]
    };
  },
  data() {
    return {
      refreshKey: 0,
      hasTokens: this.$store.state.reviewManagement.hasTokens,
      tokens: this.$store.state.reviewManagement.tokens
    };
  },
  created() {},
  methods: {
    onEdit({
      item,
      context
    }) {
      this.$refs.modalUpdateConnection.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    onRemove({
      item,
      context
    }) {
      this.$refs.modalRemoveConnection.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    onAdded() {
      this.refreshKey++;
      this.hasItems = true;
    },
    onCreateConnection() {
      this.$access.hasOrThrow("connections");
      this.$refs.modalAddConnection.open();
    }
  },
  computed: {
    tableKey() {
      return this.refreshKey;
    },
    dataCollectionTokens() {
      let count = 0;
      this.tokens.forEach(item => {
        if (item.initial_ready === 0 && item.status !== 10 && item.status !== 20) {
          count++;
        }
      });
      return count;
    }
  },
  watch: {
    "$store.state.reviewManagement.hasTokens": function () {
      this.hasTokens = this.$store.state.reviewManagement.hasTokens;
    },
    "$store.state.reviewManagement.tokens": function () {
      this.tokens = this.$store.state.reviewManagement.tokens;
    }
  },
  components: {
    ModalAddConnection,
    ModalUpdateConnection,
    ModalRemoveConnection,
    MainTitle,
    ConnectionsTable,
    //Warning,
    Badge,
    WarningAllLK
  }
};