/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{ref:"table",attrs:{"table-id":"connections","columns":_vm.columns,"loader":_vm.tableLoader,"item-id":"id","selectable":false},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status-wrap"},[(
                    item.initial_ready === 0 &&
                    item.status !== 10 &&
                    item.status !== 20
                )?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot green"}),_c('spinner',{attrs:{"dark":true}}),_c('span',{staticClass:"status-wrap__item__text"},[_vm._v("原始数据收集")])],1):_vm._e(),(item.status === 10)?_c('div',{staticClass:"status-wrap__item"},[_c('spinner',{attrs:{"dark":true}}),_c('span',{staticClass:"status-wrap__item__text"},[_vm._v("令牌验证")])],1):_vm._e(),(item.status === 20)?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot red"}),_c('span',{staticClass:"status-wrap__item__text red"},[_vm._v("错误")])]):_vm._e(),(
                    item.status === 30 &&
                    item.state !== 20 &&
                    item.initial_ready === 1
                )?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot green"}),_c('span',{staticClass:"status-wrap__item__text green"},[_vm._v("已连接")])]):_vm._e(),(
                    item.status === 30 &&
                    item.state === 20 &&
                    item.initial_ready === 1
                )?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot gray"}),_c('span',{staticClass:"status-wrap__item__text green"},[_vm._v("访问受限")])]):_vm._e(),(item.status === 50 && item.initial_ready === 1)?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot gray"}),_c('span',{staticClass:"status-wrap__item__text green"},[_vm._v("不再有效")])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }